






















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Global } from '@/store';

@Component({ components: {} })

export default class PowerConsumptionReport extends Vue {
    @Global.State('lang') lang;

    menu = false;
}
